<template>
  <b-card no-body title="Listings">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="onLoadAllListings">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getAllListings" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="isSortDesc" sort-icon-left no-local-sorting>
        <template #cell(name)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
        </template>
        <template #cell(created)="data">
          {{ showDateInFormat(data.value) }}
        </template>
        <template #cell(listingPrice)="data"> {{ showAsMoney(data.value) }} ({{ data.item.priceType }}) </template>
        <template #cell(availableQuantity)="data"> {{ data.value }} ({{ data.item.quantityType }}) </template>
        <template #cell(pkgQuantity)="data"> {{ data.value }} ({{ data.item.forPackage.unitOfMeasureAbbreviation }}) </template>
        <template #cell(images)="data"> {{ data.value.length }} </template>
        <template #cell(sampleRequests)="data"> {{ data.value.length }} </template>
        <template #cell(pkgProduct)="data"> {{ data.value }}<br /><br />{{ data.item.forPackage.itemStrainName }} </template>
        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="6" class="mb-1">
                <span v-for="item in row.item.tags" :key="item">
                  <b-badge variant="light-primary">{{ item }}</b-badge> &nbsp;
                </span>
              </b-col>
            </b-row>
            <b>Description:</b> {{ row.item.description }}<br />
            <b>Location:</b> {{ row.item.location }}<br />
            <b>Brand:</b> {{ row.item.brand }}<br />
            <br />
            <b>Listing UID:</b> {{ row.item.listingUid }} <br />
            <br />
            <b>Samples:</b> {{ row.item.sampleRequests }}<br />
            <b>Images:</b> {{ row.item.images }}<br />
            <b>Deal Status:</b> {{ row.item.dealDetails }}<br />
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>
        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { getAllListings, filterBy, setPerPage, getLoading, onLoadAllListings, getTotalRows, getPerPage, sortBy, sortOrder } from "@/views/listings/listingRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { toMoneyUS } from "@/service/NumberService";

import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "AllListings",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  props: {
    listingRow: null
  },
  setup(props, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const fields = [
      // 'mark_resolved',
      {
        key: "name",
        label: "Name",
        sortable: true
      },
      "allowSampleRequests",
      "entirePackageOnly",
      {
        key: "listingPrice",
        label: "Listing Price",
        sortable: true
      },
      {
        key: "availableQuantity",
        label: "Listing Qty",
        sortable: true
      },
      {
        key: "pkgQuantity",
        label: "Package Qty"
      },

      {
        key: "forPackage.label",
        label: "Metrc Tag"
      },

      {
        key: "pkgProduct",
        label: "Product Name"
      },

      "dealStatus",
      {
        key: "sampleRequests",
        label: "# Samples"
      },
      "images",
      "created"
    ];

    const isSortDesc = ref(false);
    watch(filterBy, (newValue, oldValue) => {
      initalLoadListings();
    });

    watch(sortBy, (newValue, oldValue) => {
      initalLoadListings();
    });
    watch(isSortDesc, (newValue, oldValue) => {
      sortOrder.value = newValue ? "desc" : "asc";
      initalLoadListings();
    });
    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const showAsMoney = (amt) => toMoneyUS(amt);

    const initalLoadListings = async () => onLoadAllListings({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadListings();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllListings({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllListings({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllListings({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      if (props.listingRow) {
        filterBy.value = props.listingRow.orgName;
      } else {
        filterBy.value = "";
      }
    });

    return {
      getAllListings,
      filterBy,
      setPerPage,
      getLoading,
      onLoadAllListings,
      getTotalRows,
      getPerPage,
      onPageChanged,
      perPageChanged,
      showDateInFormat,
      currentPage,
      pageOptions,
      fields,
      showAsMoney,
      sortBy,
      sortOrder,
      isSortDesc
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
